import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/store";
import { LayoutElement } from "@/app/_types";
import ArticleSearchAutocomplete from "./ArticleSearchAutocomplete";
import { stripLink } from "@/app/_utils/formatter";

export interface ArticleProps {
  articleId: string;
  title: string;
}

interface ArticleSearchProps {
  onSelectArticle: (article: ArticleProps) => void;
  onSelectRandom?: (articleId: string) => void;
  onClear?: (input: string) => void;
  initialArticle?: ArticleProps;
  autoFocus?: boolean;
  portal?: boolean;
}

const ArticleSearch: React.FC<ArticleSearchProps> = ({
  onSelectArticle,
  onSelectRandom,
  onClear,
  initialArticle,
  autoFocus = true,
  portal = false,
}) => {
  const articles = useSelector((state: RootState) => state.articles).articles;

  const formattedArticles = useMemo(
    () =>
      Object.keys(articles).map((key) => {
        const article = articles[key];

        let flattenedArticleData = "";
        article.layout.forEach((articleLayoutItem: LayoutElement) => {
          if ("text" in articleLayoutItem) {
            flattenedArticleData += ` ${articleLayoutItem.text}`;
          }
        });
        return {
          articleId: article.id,
          title: stripLink(article.title),
          text: flattenedArticleData,
          article,
        };
      }),
    [articles],
  );

  return (
    <ArticleSearchAutocomplete
      autoFocus={autoFocus}
      data={formattedArticles}
      valueKey="title"
      onSelect={(selectedArticle) => {
        onSelectArticle(selectedArticle);
      }}
      placeholder={initialArticle?.title || "Search Article..."}
      onSelectRandom={onSelectRandom}
      onClear={onClear}
      initialValue={initialArticle?.title}
      portal={portal}
    />
  );
};

export default ArticleSearch;
