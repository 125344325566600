"use client";
import { cn } from "@/app/_utils/ui";
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Input } from "../forms/input";
import { searchText } from "@/app/_utils/helper";
import { SearchOutlined, CloseOutlined, BookOutlined } from "@ant-design/icons";
import { stripLink } from "@/app/_utils/formatter";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../reducers/store";
import { searchArticle } from "../../reducers/analyticsSlice";
import ArticleRandom from "../search/ArticleRandom";

interface AutocompleteProps<T> {
  data: T[];
  valueKey: keyof T;
  onSelect: (item: T) => void;
  placeholder?: string;
  initialValue?: string;
  autoFocus?: boolean;
  onSelectRandom?: (articleId: string) => void;
  onClear?: (input: string) => void;
  portal?: boolean; // Optional prop for portal rendering
}

const ArticleSearchAutocomplete = <T extends { [key: string]: any }>({
  data,
  valueKey,
  onSelect,
  placeholder = "Search...",
  initialValue = "",
  autoFocus,
  onSelectRandom,
  onClear,
  portal = false, // Set default value for portal
}: AutocompleteProps<T>) => {
  const [query, setQuery] = useState("");
  const [inputValue, setInputValue] = useState(initialValue);
  const [showOptions, setShowOptions] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(
    (state: RootState) => state.login.accessToken,
  );

  const containerRef = useRef<HTMLDivElement>(null); // Ref to track the input container
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);
  const [dropdownStyles, setDropdownStyles] = useState<React.CSSProperties>({});

  useEffect(() => {
    if (portal) {
      setPortalRoot(document.body);
    }
  }, [portal]);

  useEffect(() => {
    if (showOptions && portal && containerRef.current) {
      // Use the ref to calculate the correct position relative to the input field
      const rect = containerRef.current.getBoundingClientRect();
      setDropdownStyles({
        position: "absolute",
        top: rect.bottom + window.scrollY, // Consider page scroll
        left: rect.left + window.scrollX,
        width: rect.width,
        zIndex: 9999,
      });
    }
  }, [showOptions, portal]);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const handleSelect = (item: T) => {
    onSelect(item);
    dispatch(
      searchArticle({
        accessToken,
        articleId: item.article.id,
        search: query,
      }),
    );
    setInputValue(item[valueKey]);
    setQuery("");
    setShowOptions(false);
  };

  const clearInput = () => {
    if (onClear) {
      onClear(inputValue);
    }
    setInputValue("");
    setQuery("");
    setShowOptions(false);
    dispatch(
      searchArticle({
        accessToken,
        articleId: "cancel",
        search: query,
      }),
    );
  };

  const filteredItems = data
    .map((item) => {
      const titleMatch = searchText(stripLink(item.title), query);
      const textMatch = searchText(item.text, query, true);
      return {
        ...item,
        titleMatch,
        textMatch,
      };
    })
    .filter((item) => item.titleMatch !== null || item.textMatch !== null)
    .sort((a, b) => {
      const aVault = a.article?.status?.vault ? 1 : 0;
      const bVault = b.article?.status?.vault ? 1 : 0;
      if (aVault !== bVault) return aVault - bVault;

      const aTitleMatch = a.titleMatch ? 1 : 0;
      const bTitleMatch = b.titleMatch ? 1 : 0;
      if (aTitleMatch !== bTitleMatch) return bTitleMatch - aTitleMatch;

      return a.title.localeCompare(b.title);
    });

  const boldMatch = (text: string, match: string) => {
    if (!match) return <div>{text}</div>;

    const matchStart = text.toLowerCase().indexOf(match.toLowerCase());
    if (matchStart === -1) return <div>{text}</div>;

    const matchEnd = matchStart + match.length;
    const beforeMatch = text.substring(0, matchStart);
    const matchText = text.substring(matchStart, matchEnd);
    const afterMatch = text.substring(matchEnd);

    return (
      <div>
        {beforeMatch}
        <strong className="font-bold">{matchText}</strong>
        {afterMatch}
      </div>
    );
  };

  const dropdownContent =
    inputValue !== "" && filteredItems.length > 0 ? (
      filteredItems.map((item, index) => {
        const title = item.article?.status?.vault
          ? `Vault: ${stripLink(item[valueKey])}`
          : stripLink(item[valueKey]);
        return (
          <button
            key={index}
            className="px-4 py-2 text-base text-left cursor-pointer w-full hover:bg-neutral-200"
            onClick={() => handleSelect(item)}
          >
            <div className="line-clamp-1">{boldMatch(title, query)}</div>
            {!item.titleMatch && item.textMatch && (
              <div className="text-sm text-neutral-700 line-clamp-1">
                {boldMatch(
                  stripLink(item.textMatch).split(" ").slice(1).join(" "),
                  query,
                )}
              </div>
            )}
          </button>
        );
      })
    ) : (
      <div
        className={cn(
          "px-4 py-2 text-base text-neutral-700",
          filteredItems.length > 0 && "hidden pointer-events-none",
        )}
      >
        No results found
      </div>
    );

  return (
    <div ref={containerRef} className="relative z-10 w-full">
      <div className="relative">
        {isFocused ? (
          <SearchOutlined className="absolute top-2 left-3" />
        ) : (
          <BookOutlined className="absolute top-2 left-3" />
        )}
        <Input
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            setQuery(e.target.value);
            setShowOptions(true);
          }}
          onFocus={() => {
            setInputValue("");
            setQuery("");
            setIsFocused(true);
          }}
          onBlur={() => setIsFocused(false)}
          autoFocus={autoFocus}
          className="pl-10 pr-9 overflow-ellipsis"
        />
        <CloseOutlined
          className="absolute top-2 right-3 cursor-pointer scale-90 opacity-60"
          onClick={clearInput}
        />
      </div>

      {showOptions &&
        (portal ? (
          portalRoot &&
          ReactDOM.createPortal(
            <div
              style={dropdownStyles}
              className="border bg-white max-h-[40vh] overflow-auto rounded shadow-md z-30 pointer-events-auto"
            >
              {dropdownContent}
              {onSelectRandom && (
                <ArticleRandom
                  className="sticky bottom-0 left-0"
                  variant="dropdown"
                  onSelectArticle={onSelectRandom}
                />
              )}
            </div>,
            portalRoot,
          )
        ) : (
          <div className="absolute w-full border -mt-px bg-white max-h-[40vh] overflow-auto top-9 rounded shadow-md z-30">
            {dropdownContent}
            {onSelectRandom && (
              <ArticleRandom
                className="sticky bottom-0 left-0"
                variant="dropdown"
                onSelectArticle={onSelectRandom}
              />
            )}
          </div>
        ))}
    </div>
  );
};

export default ArticleSearchAutocomplete;
